<div class="root is-align-self-stretch">
  <div class="columns is-marginless has-background-info p-2">
    <div class="column is-full p-0">
      <app-dropdown-bar [isPublicMode]="isPublicMode" (onCenterSelection)="onCenterSelection($event)" />

      <button *ngIf="permissions.reports" class="button is-small is-radiusless report-download-button" [class.is-loading]="loaders.reportDownload" (click)="exportCamReport()">Download Cam Report</button>
      <a [href]="'/command-center'" *ngIf="permissions.commandCenter" class="button is-small is-radiusless report-download-button ml-2">Command Center</a>
      <span *ngIf="profile" class="is-pulled-right has-text-weight-bold has-text-white">Hello {{profile.email}}!</span>

    </div>
  </div>
  <div class="remarks" *ngIf="selectedCenter && selectedCenter.additionalRemarks">
    {{ selectedCenter.additionalRemarks }}
  </div>
  <div class="columns" style="background-color: transparent">
    @if(Cameras.length > 0){
      <div class="column p-4">
        @for(cam of Cameras; track cam.id){
          <app-video
            [Camera]="cam"
            [ShowRecordingButton]="permissions.recording"
            [height]="214"
            [width]="380"
            class="cam-thumbnail">
          </app-video>
        }
      </div>
    }

    @if(Cameras.length === 0){
      <div class="column is-5 has-text-centered" style="position: relative">
        <img
          class="is-block mt-5 ml-5"
          src="assets/start-herer.png"
          style="width: auto; height: 200px"
          alt="start here" />
      </div>
      <div class="column is-7 has-text-centered has-vertically-centered">
        <br />
        <br />
        @for(dummy of [1,2,3,4]; track dummy){
          <div class="dummy-cam has-text-left">
            <app-video
              [height]="214"
              [width]="380"
            ></app-video>
          </div>
        }
      </div>
    }
  </div>
  @if(!!selectedCenter && !isPublicMode){
    <div class="columns p-2">
      <div class="column">
        <div class="remarks">
          Running Batches
        </div>
        <progress *ngIf="loaders.batches" class="progress is-small is-danger" max="100" style="border-radius: 0; height: 5px; margin-bottom: 0"></progress>

        <div class="table-container">
          <table class="table">
            <thead>
            <tr>
              <th>Batch Code</th>
              <th>Aspirants (present/total)</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Timing</th>
              <th>Course</th>
              <th>Center</th>
            </tr>
            </thead>
            <tbody>
            @for(batch of RsldcBatches; track batch.batchCode){
              <tr>
                <td>{{batch.batchCode}}</td>
                <td>{{batch.presentAspirants}} / {{batch.underTrainingAspirants}}</td>
                <td>{{dateFormat(batch.batchStartDate ?? '','DD-MM-YYYY')}}</td>
                <td>{{dateFormat(batch.batchEndDate ?? '', 'DD-MM-YYYY')}}</td>
                <td>{{batch.batchStartTime}} - {{batch.batchEndTime}}</td>
                <td>
                  {{batch.sector}} - {{batch.batchType}} <br />
                  {{batch.batchCourse}}
                </td>
                <td>
                  Head: {{batch.centerHeadName}}<br/>
                  Ph: {{batch.centerHeadContactNo}} <br />
                  SDC: {{batch.sdcCode}}<br />
                  Address: {{batch.sdcAddress}}
                </td>
              </tr>
            }
            </tbody>
            <tfoot>

            </tfoot>
          </table>

        </div>
      </div>
    </div>
  }
</div>
