
export const strip_text = (content?: string, length: number = 20) => content && content?.length <= length ? content : `${content?.substring(0, length)}...`;
export const convertToCSV = (objArray: any[]): string => {
  let array = objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line != '') line += ','
      line += array[i][index];
    }

    str += line + '\r\n';
  }
  return str;
}

export const exportCSVFile = (headers: any, items: any[], fileTitle: string) => {
  if (headers) {
    items.unshift(headers);
  }
  let csv = convertToCSV(items);
  let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  let link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilenmae);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
