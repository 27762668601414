import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company, OrganisationClient, Scheme, TrainingCenter} from "../schematics/openApiClients";
import {NgIf} from "@angular/common";
import {AppModule} from "../app.module";
import {DropdownItem, Dropdowns, DropdownSelections} from "../common/interfaces";
import {lastValueFrom} from "rxjs";
import {sortBy} from "lodash";
import {ActivatedRoute} from "@angular/router";
import {generateQueryString} from "../common/constants";

@Component({
  selector: 'app-dropdown-bar',
  templateUrl: './dropdown-bar.component.html',
  styleUrl: './dropdown-bar.component.scss'
})
export class DropdownBarComponent implements OnInit {
  constructor(private orgClient: OrganisationClient, private route: ActivatedRoute) {
  }
  @Input() isPublicMode = false;
  @Input() hide: string[] = [];

  @Output() onSchemeSelection = new EventEmitter<Scheme | undefined>();
  @Output() onCompanySelection = new EventEmitter<Company | undefined>();
  @Output() onDistrictSelection = new EventEmitter<string | undefined>();
  @Output() onCenterSelection = new EventEmitter<TrainingCenter | undefined>();

  dropdowns: Dropdowns = {
    schemes: [],
    companies: [],
    districts: [],
    centers: [],
    batches: [],
    cameras: [],
    actives: [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }],
    fys: []
  }
  Schemes: Scheme[] = [];
  Centers: TrainingCenter[] = [];
  Companies: Company[] = [];

  selections: DropdownSelections = {
    scheme: '',
    district : '',
    company : '',
    center : '',
    fy: ''
  };
  QP: any = {};
  loaders: Partial<{
    scheme: boolean,
    company: boolean,
    center: boolean,
    fy: boolean,
    reportDownload: boolean,
    batches: boolean
  }> = {}

  async ngOnInit() {
    this.loaders.scheme = true;
    this.loaders.fy = true;
    this.Schemes = (await lastValueFrom(this.orgClient.getSchemes()));
    this.dropdowns.schemes = this.Schemes.map(m => ({ label: m.name ?? '', value: (m.name ?? '').toLowerCase() }));
    this.dropdowns.fys = (await lastValueFrom(this.orgClient.getFinancialYears()))
      .map(m => ({label: m ?? '', value: m}));
    this.loaders.scheme = false;
    this.loaders.fy = false;

    const qp = this.route.snapshot.queryParams;
    this.QP = { ...qp }

    if(this.QP.scheme)
      await this.onSelectDDL(this.QP.scheme, 'scheme');
    if(this.QP.company)
      await this.onSelectDDL(this.QP.company, 'company');
    if(this.QP.fy)
      await this.onSelectDDL(this.QP.fy, 'fy');
    if(this.QP.district)
      await this.onSelectDDL(this.QP.district, 'district');
    if(this.QP.center)
      await this.onSelectDDL(this.QP.center, 'center');

  }

  async onDropdownSelectionChange(e: DropdownItem, type: string){
    await this.onSelectDDL(e?.value.toString(), type);
    generateQueryString(this.selections, this.route.snapshot.queryParams);
  }

  async onSelectDDL(itemValue: string | undefined, ddlType: string) {
    switch (ddlType) {
      case 'scheme':
        this.selections = {
          ...this.selections,
          scheme : itemValue ?? '',
          company: '',
          fy: '',
          district: '',
          center: ''
        }
        this.onSchemeSelection.emit(this.Schemes.find(m => m.name?.toLowerCase() === itemValue?.toLowerCase()));
        this.loaders.company = true;
        this.Companies = await lastValueFrom(this.orgClient.getCompanies(this.selections.scheme ?? 'NANANANA'));
        this.dropdowns.companies = this.Companies.map(m => ({label: m.name ?? '', value: m.id}));
        this.loaders.company = false;
        await this.onSelectDDL(undefined, 'company');
        break;

      case 'company':
        this.selections = {
          ...this.selections,
          company : itemValue ?? '',
          fy: '',
          district: '',
          center: ''
        }
        this.onCompanySelection.emit(this.Companies.find(m => m.id.toString() === itemValue));
        this.loaders.center = true;
        const company = parseInt((this.selections.company === '' ? undefined: this.selections.company) ?? '0');
        this.Centers =
          await lastValueFrom(this.orgClient.getTrainingCenters(this.selections.scheme ?? '', (company === 0 ? null : company), null));

        this.dropdowns.centers = this.Centers.map(m => ({label: m.centerName ?? '', value: m.id }));
        this.dropdowns.districts =[...new Set(this.Centers.map(m => m.district))].map(m => ({label : m ?? '', value : m ?? ''}));
        this.dropdowns.districts = sortBy(this.dropdowns.districts, 'label');
        this.loaders.center = false;
        break;

      case 'fy':
        this.selections = {
          ...this.selections,
          fy : itemValue ?? ''
        }
        this.getFilteredCenters();
        break;

      case 'district':
        this.selections = {
          ...this.selections,
          district : itemValue ?? ''
        }
        this.getFilteredCenters();
        break;

      case 'center':
        this.selections = {
          ...this.selections,
          center : itemValue ?? ''
        }
        this.onCenterSelection.emit(this.Centers.find(m => m.id.toString() === itemValue));
        break;
    }
  }

  getFilteredCenters(){
    this.dropdowns.centers = this.Centers.filter(m => {
      const c1 = m.district === (this.selections.district === '' ? m.district : this.selections.district);
      const c2 = m.fy === (this.selections.fy === '' ? m.fy : this.selections.fy);
      return c1 && c2;
    }).map(m => ({label: m.centerName ?? '', value: m.id }));

  }

}
