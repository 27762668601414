import {Component, OnInit} from '@angular/core';
import {
  Camera,
  CommandCenterClient, Company,
  FilterObjects,
  ICamera,
  IFilterObjects,
  Scheme, TrainingCenter
} from "../../schematics/openApiClients";
import {AuthService} from "@auth0/auth0-angular";
import {interval, lastValueFrom, Subscription} from "rxjs";
import {CommandCenterQuery, DropdownItem, Dropdowns} from "../../common/interfaces";
import {
  faAngleLeft, faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowCircleLeft, faArrowCircleRight,
  faCogs, faHome, faPause, faPlay
} from "@fortawesome/free-solid-svg-icons";
import {drop} from "lodash";
import {generateQueryString, videoBoxSizes} from "../../common/constants";
import {query} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-command-center',
  templateUrl: './command-center.component.html',
  styleUrl: './command-center.component.scss'
})
export class CommandCenterComponent implements OnInit {
  constructor(private commandCenter: CommandCenterClient, private router: ActivatedRoute){}
  Cameras: Camera[] = [];
  settingsWindow = false;
  timer$!: Subscription | null;
  qp: any = {};

  selections = {
    box_size: videoBoxSizes[0].h + ',' + videoBoxSizes[0].w,
    page_size: 15,
    page: 1,
    delay: 10,
    auto_play: false,
  }

  query: CommandCenterQuery = {
    cameraId: '',
    centerId: 0,
    district: '',
    companyId: 0,
    scheme: '',
    page: this.selections.page,
    pageSize: this.selections.page_size,
    total: 0
  }



  icons = {
    settings: faCogs,
    left: faAngleLeft,
    right: faAngleRight,
    home: faHome,
    play: faPlay,
    pause: faPause,
  }

  settings = {
    dropdown_box_size: videoBoxSizes.map(e => ({
      label: `${e.h} x ${e.w}`,
      value: `${e.h},${e.w}`,
    }) as DropdownItem),
    page_sizes: [5,10,15,20,25,30,35,40,45,50].map(e => ({
      label: e.toString(),
      value: e.toString(),
    }) as DropdownItem),
    start_page: () => Array.from({ length: this.totalPages }, (value, index) => index + 1).map(e => ({
      label: e.toString(),
      value: e.toString(),
    }) as DropdownItem),
    slide_show_delay: [5,10,15,20,25,30,35,40,45,50,55,60].map(e => ({
      label: e.toString(),
      value: e.toString(),
    }) as DropdownItem)
  }

  async ngOnInit() {
    this.qp = this.router.snapshot.queryParams;
    if(this.qp.page){
      this.query.page = this.qp.page;
      this.selections.page = this.qp.page;
    }

    if(this.qp.page_size && this.settings.page_sizes.findIndex(m => m.value === this.qp.page_size) > -1) {
      this.query.pageSize = this.qp.page_size;
      this.selections.page_size = this.qp.page_size;
    }

    if(this.qp.box_size && this.settings.dropdown_box_size.findIndex(m => m.value === this.qp.box_size) > -1){
      this.selections.box_size = this.qp.box_size;
    }

    if(this.qp.delay && this.settings.slide_show_delay.findIndex(m => m.value === this.qp.delay) > -1){
      this.selections.delay = this.qp.delay;
    }

    if(!!this.qp.auto_play)
    {
      await this.start_stop_auto_pagination();
      this.selections.auto_play = true;
    }

    await this.fetchCameras();
  }

  async fetchCameras() {
    //this.Cameras = [];
    const response = await lastValueFrom(this.commandCenter.getCameras(
      this.query.cameraId,
      this.query.centerId,
      this.query.companyId,
      this.query.district,
      this.query.scheme,
      this.query.page,
      this.query.pageSize
    ))

    this.query.total = response.totalCount;
    this.Cameras = response.results ?? [];
  }

  get totalPages(){
    return Math.ceil(this.query.total / this.query.pageSize);
  }

  async paginate(dir: number){
    if(dir === 1){
      this.query.page++;
      if(this.query.page > this.totalPages)
        this.query.page = 1;
    }

    if(dir === -1){
      this.query.page--;
      if(this.query.page < 1)
        this.query.page = this.totalPages;
    }

    this.selections.page = this.query.page;
    generateQueryString(this.selections, this.router.snapshot.queryParams)
    await this.fetchCameras();
  }



  async onSchemeSelection(scheme: Scheme | undefined) {
    this.query.scheme = scheme?.name ?? '';
    await this.fetchCameras();
  }

  async onCompanySelection(company: Company | undefined) {
    this.query.companyId = company?.id ?? 0;
    await this.fetchCameras();
  }

  async onDistrictSelection(district: string | undefined) {
    this.query.district = district ?? '';
    await this.fetchCameras();
  }

  async onCenterSelection(center: TrainingCenter | undefined) {
    this.query.centerId = center?.id ?? 0;
    await this.fetchCameras();
  }

  async onBoxSizeChange(size: DropdownItem) {
    if(size)
      this.selections.box_size = size.value.toString();
    else
      this.selections.box_size = this.settings.dropdown_box_size[0].value.toString();
  }

  async onPageSizeChange(size: DropdownItem) {
    if(size)
      this.selections.page_size = parseInt(size.value.toString());
    else
      this.selections.page_size = 15

    this.query.pageSize = this.selections.page_size;
  }

  async onStartPageChange(page: DropdownItem) {
    if(page)
      this.selections.page = parseInt(page.value.toString());
    else
      this.selections.page = 1;

    this.query.page = this.selections.page;
  }

  async onSlideShowDelayChange(delay: DropdownItem) {
    if(delay)
      this.selections.delay = parseInt(delay.value.toString());
    else
      this.selections.delay = 10;
  }

  async start_stop_auto_pagination(stop: boolean = false){
    if(stop && this.timer$){
      this.timer$.unsubscribe();
      this.timer$ = null;
    }
    else{
      this.timer$ = interval(this.selections.delay * 1000)
        .subscribe(async res => {
          await this.paginate(1);
        });
    }
  }

  get selected_video_box_size() {
    return {
      h: parseInt(this.selections.box_size.split(',')[0]),
      w: parseInt(this.selections.box_size.split(',')[1])
    }
  }

  async saveSettings() {
    this.settingsWindow = false;
    await this.start_stop_auto_pagination(true);
    await this.fetchCameras();

    if(this.selections.auto_play)
      await this.start_stop_auto_pagination();

    generateQueryString(this.selections, this.router.snapshot.queryParams);
  }

  async auto_paginate() {
    this.selections.auto_play = !this.selections.auto_play

    if(this.totalPages > 1)
    {
      if(this.selections.auto_play){
        await this.start_stop_auto_pagination();
      }

      if(!this.selections.auto_play){
        await this.start_stop_auto_pagination(true);
      }
    }
    else
      this.selections.auto_play = false;

    generateQueryString(this.selections, this.router.snapshot.queryParams);
  }
}
