import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";
import {DOCUMENT} from "@angular/common";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'Angular 12 - Angular v2 Sample';
  isIframe = false;
  loginDisplay = false;
  isAuthenticated: boolean;
  core_servers: string[] = [];
  active_inactive = ['Live Data', 'Inactive/Archived Data'];
  private readonly _destroying$ = new Subject<void>();
  server: string = "";
  active_inactive_selected = this.active_inactive[0];
  constructor(@Inject(DOCUMENT) private document: Document,
              private authService: AuthService) {
    this.isAuthenticated = false;


  }

  get isPublicMode(){
    return environment.publicMode;
  }

  ngOnInit() {
    this.server = localStorage.getItem('server-url') ?? environment.apiService.url;
    this.active_inactive_selected = localStorage.getItem('active_inactive') ?? this.active_inactive[0];
    this.core_servers = [environment.apiService.url, ...environment.apiService.servers];

     this.authService.isAuthenticated$.subscribe((success: boolean) => {
      this.isAuthenticated = success;
    });
  }

  logout(popup?: boolean) {
    this.authService.logout({
      logoutParams: {
        returnTo: this.document.location.origin
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  server_selected() {
    localStorage.setItem('server-url', this.server);
    location.reload();
  }

  on_active_inactive_selected(){
    localStorage.setItem('active_inactive', this.active_inactive_selected);
    location.reload();
  }
}
